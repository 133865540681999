body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
  color: #0A506D;
}

.app-container {
  position: relative;
  min-height: 100vh;
}

.header {
  text-align: center;
  padding: 20px 0;
}

.logo {
  width: 70%;
  max-width: 400px;
  margin: 0 auto;
  filter: drop-shadow(10px 10px 10px #000);
}

.text-logo {
  width: 70%;
  max-width: 400px;
  margin: 20px auto;
  display: block;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  text-align: center;
}

.section {
  padding: 40px 0;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s, transform 1s;
}

.section.in-view {
  opacity: 1;
  transform: none;
}

.paper {
  padding: 32px;
  background-color: transparent !important;
  color: #0A506D !important;
}

.input {
  background-color: #666666 !important;
  color: #FFF !important;
}

.label {
  color: #FFF !important;
}

.carousel-slide img {
  width: 100%;
  border-radius: 8px;
}

.slick-center .carousel-slide img {
  transform: scale(1);
}

.slick-slide {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.slick-center {
  opacity: 1;
}

.slick-slide img {
  border: 1px solid #666;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.slick-center img {
  transform: scale(1);
}

.slick-prev,
.slick-next {
  z-index: 3;
}

.slick-prev:before,
.slick-next:before {
  color: #888;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #666;
}

.slick-dots li.slick-active button:before {
  color: #000;
}

.video-container {
  position: relative;
  max-width: 90%; /* Adjust this to match the portrait aspect ratio */
  max-height: 80vh; /* 80% of the viewport height */
  overflow: hidden;
  background: #000;
  border-radius: 8px;
  margin: 0 auto; /* Center horizontally */
}

.video-container::before {
  content: "";
  display: block;
  padding-top: 177.78%; /* 9:16 aspect ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.instagram-feed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  padding-bottom: calc(180px + 40%);
  height: 0;
  overflow: hidden;
  width: 100%;
}

.instagram-feed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.download-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.store-link {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.store-badge {
  height: 80px;
  width: unset;
  margin: 10px;
}

.download-buttons .MuiButtonBase-root {
  margin: 10px;
}

@media (max-width: 800px) {
  .download-buttons {
    flex-direction: column;
  }

  .store-badge {
    height: unset;
    width: 200px;
  }
}

.footer {
  color: white;
  text-align: center;
  padding: 20px 20px 120px;
  background-color: #0A506D;
}